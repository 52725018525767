const sidebarNav = [
  {
    link: "/customers",
    section: "customers",
    icon: "ph:users-bold",
    text: "Customers",
  },
  {
    link: "/orders",
    section: "orders",
    icon: "ph:users-bold",
    text: "Orders",
  },
  {
    link: "/products",
    section: "products",
    icon: "icon-park-outline:ad-product",
    text: "Products",
  },
  
  {
    link: "/analytics",
    section: "analytics",
    icon: "carbon:analytics",
    text: "Analytics",
  },
  // {
  //   link: "/discount",
  //   section: "discount",
  //   icon: "nimbus:discount-circle",
  //   text: "Discount",
  // },
  // {
  //   link: "/inventory",
  //   section: "inventory",
  //   icon: "ic:round-inventory",
  //   text: "Inventory",
  // },
];

export default sidebarNav;
